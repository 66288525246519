// WeSocketMessageContext.js
import React, { createContext, useContext, useEffect, useCallback } from 'react';
import { useWebSocket } from './WebSocketContext';
import { useChat } from './ChatContext';
import { useAuth } from './AuthContext';
import { useSearch } from './SearchContext';
const WebSocketMessageContext = createContext(null);

export const WebSocketMessageProvider = ({ children }) => {
    const { socket } = useWebSocket();
    const {
      handleIncomingMessage,
      handleNewChatCreated,
      setActiveChat,
      addUserMessage,
      activeChat,
    } = useChat();
    const { handleSearchResults } = useSearch();
    const { refreshAccessToken } = useAuth();
    
    const handleTokenRefresh = useCallback(async () => {
        try {
            const newToken = await refreshAccessToken();
            if (socket?.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({
                    type: 'token_refresh',
                    data: {
                        token: newToken
                    }
                }));
            }
        } catch (error) {
            console.error('Token refresh failed:', error);
        }
    }, [socket, refreshAccessToken]);

    const handlePDFCompletion = useCallback((data) => {
      if (data.document_id) {
        socket.send(JSON.stringify({
          type: 'load_pdf_content',
          data: { document_id: data.document_id }
        }));
      }
    }, [socket]);
  
    const handlePDFContent = useCallback((data) => {
      if (data.content) {
        addUserMessage(`PDF document loaded: ${data.document_id}`);
      }
    }, [addUserMessage]);
  
    useEffect(() => {
      if (!socket) return;
  
      const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        
        switch (data.type) {
          case 'chat':
            handleIncomingMessage(data.data);
            break;
          case 'new_chat':
            console.log('New chat created:', data.data.chat_id);
            handleNewChatCreated(data.data.chat_id);
            break;
          case 'chat_switched':
            console.log('Chat switched to:', data.data.chat_id);
            // Only set active chat if it's different
            if (data.data.chat_id !== activeChat) {
                setActiveChat(data.data.chat_id);
            }
            break;
          case 'citation':
            console.log('Citation:', data.data);
            break;
          case 'pdf_upload_complete':
            handlePDFCompletion(data.data);
            break;
          case 'pdf_content':
            handlePDFContent(data.data);
            break;
          case 'processing_status':
            console.log('Processing status:', data.data);
            break;
          case 'token_refresh_required':
            handleTokenRefresh();
            break;
          case 'token_refreshed':
            console.log('Token refreshed successfully');
            break;
          case 'ping':
            console.log('Received ping:', data.data);
            break;
            case 'vector_search_results':
                handleSearchResults(data.data);
                break;
        //   case 'vector_search_results':
        //     console.log('Vector_search:', data.data);
        //     break;
          case 'error':
            console.error('Error from server:', data.error);
            break;
          default:
            console.warn('Unknown message type:', data.type);
        }
        requestAnimationFrame(() => window.scrollTo(0, document.body.scrollHeight));
      };
  
      socket.addEventListener('message', handleMessage);
      return () => socket.removeEventListener('message', handleMessage);
    }, [
      socket,
      handleIncomingMessage,
      handleNewChatCreated,
      setActiveChat,
      handlePDFCompletion,
      handlePDFContent
    ]);
  
    return (
      <WebSocketMessageContext.Provider 
        value={{
          handlePDFCompletion,
          handlePDFContent
        }}
      >
        {children}
      </WebSocketMessageContext.Provider>
    );
  };

  export const useWebSocketMessage = () => {
    const context = useContext(WebSocketMessageContext);
    if (!context) {
      throw new Error('useWebSocketMessage must be used within WebSocketMessageProvider');
    }
    return context;
  };
