import React, { useState, useEffect } from 'react';
import { Box, Text, Progress, Button } from '@chakra-ui/react';
import { useWebSocket } from '../contexts/WebSocketContext';
import { v4 as uuidv4 } from 'uuid';

function FileUploadComponent({ onUploadComplete }) {
  const { socket, isConnected } = useWebSocket();
  const [uploadStatus, setUploadStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const resetState = () => {
    setUploadStatus(null);
    setProgress(0);
    setIsUploading(false);
    setError(null);
  };

  const handleFileUpload = async (file) => {
    try {
      if (!isConnected) {
        throw new Error('WebSocket not connected');
      }

      resetState();
      setIsUploading(true);

      const CHUNK_SIZE = 1024 * 1024; // 1MB chunks
      const sessionId = uuidv4();

      // Start Signal
      socket.send(JSON.stringify({
        type: 'pdf_upload',
        data: {
          subtype: 'start',
          session_id: sessionId,
          filename: file.name,
          size: file.size,
          total_chunks: Math.ceil(file.size / CHUNK_SIZE)
        }
      }));

      // Chunks senden mit Pause zwischen den Chunks
      for (let start = 0; start < file.size; start += CHUNK_SIZE) {
        const chunk = file.slice(start, start + CHUNK_SIZE);
        socket.send(chunk); // Binäre Daten
        
        // Kleine Pause zwischen Chunks für bessere Server-Verarbeitung
        await new Promise(resolve => setTimeout(resolve, 10));
      }

      // Complete Signal
      socket.send(JSON.stringify({
        type: 'pdf_upload',
        data: {
          subtype: 'complete',
          session_id: sessionId
        }
      }));

      // Progress wird jetzt vom Server gesteuert
      setUploadStatus('Finalizing upload...');

    } catch (error) {
      console.error('Upload error:', error);
      setError(error.message || 'An error occurred during upload');
      setIsUploading(false);
    }
    
  };

  useEffect(() => {
    if (!socket) return;

    const handleMessage = (event) => {
      const data = JSON.parse(event.data);
      
      if (data.type === 'processing_status') {
        const statusData = data.data;
        console.log('Received status update:', statusData);

        setUploadStatus(statusData.message);
        if (statusData.progress !== undefined) {
          setProgress(statusData.progress);
        }

        if (statusData.status === 'completed') {
          setIsUploading(false);
          if (onUploadComplete) {
            onUploadComplete(statusData.document_id);
          }
        } else if (statusData.status === 'error') {
          setError(statusData.message);
          setIsUploading(false);
        }
      }
    };

    socket.addEventListener('message', handleMessage);
    return () => socket.removeEventListener('message', handleMessage);
}, [socket, onUploadComplete]);
  return (
    <Box>
      <input 
        type="file" 
        accept=".pdf"
        onChange={(e) => {
          if (e.target.files[0]) {
            handleFileUpload(e.target.files[0]);
          }
        }}
        disabled={isUploading || !isConnected}
      />
      
      {(uploadStatus || isUploading) && (
        <Box mt={4}>
          <Text mb={2}>{uploadStatus}</Text>
          <Progress 
            value={progress} 
            size="sm" 
            colorScheme="blue" 
            borderRadius="md"
            isIndeterminate={isUploading && progress === 0}
          />
          {progress > 0 && (
            <Text fontSize="sm" mt={1}>
              Progress: {progress}%
            </Text>
          )}
        </Box>
      )}

      {error && (
        <Box mt={4} color="red.500">
          <Text>{error}</Text>
          <Button 
            mt={2} 
            size="sm" 
            onClick={resetState}
          >
            Try Again
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default FileUploadComponent;